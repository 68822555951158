<template>
  <v-container class="ma-0" fluid>
    <v-card>
      <v-card-title>Tools that should be at hand before setup process</v-card-title>
      <v-card-text>
        <v-row align="start">
          <v-col v-for="(group, index) in toolGroups" :key="index" cols="12" md="4">
            <v-spacer v-if="index === toolGroups.length - 1"></v-spacer>
            <h4 v-if="index === toolGroups.length - 1" class="mb-4">Pro Person</h4>
            <v-list dense>
              <v-list-item v-for="(item, idx) in group" :key="idx" class="mb-0">
                <v-list-item-action>
                  <v-checkbox></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'SetupInfoForm',
  data: () => ({
    toolGroups: [
      ['Setup Waage', 'Setup Räder', 'Tire Compressor', 'Shim Koffer'],
      ['13er Nuss', 'Camber Level', 'Toe Caliper', 'Toe Bar', 'Drehmomentschlüssel'],
      ['4er Inbus', '10er Gabelschlüssel', '8er Gabelschlüssel']
    ]
  })
}
</script>
