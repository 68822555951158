<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Tyres</div>

        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="tyres"
                  :items="[
                    { v: 'SLICK', t: 'Slick' },
                    { v: 'RAIN', t: 'Rain' }
                  ]"
                  item-text="t"
                  item-value="v"
                  label="Tyre Type"
                  required
                  :rules="$rules.required"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="tyreSet" :rules="$rules.required" label="Tyre Set" required />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="tyreAge" :rules="$rules.number" label="Tyre Age" suffix="km" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <div class="text-overline pa-5">Target Tyre Pressures</div>
        <setup-axis-form ref="pressures" v-model="local" unit="bar" @validate="valid = $event" />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Tyres </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupAxisForm from '@components/setup/AxisForm.vue'

const defaultConfig = {
  front: '',
  rear: ''
}

export default {
  name: 'TheSetupTyreUpdate',
  components: { SetupAxisForm },
  props: {
    setup: Object,
    config: Object,
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    copyAlert: false,
    local: {},
    tyres: '',
    tyreSet: '',
    tyreAge: ''
  }),
  created() {
    this.local = _cloneDeep(this.config || defaultConfig)
    this.tyres = this.setup.tyres
    this.tyreSet = this.setup.tyreSet
    this.tyreAge = this.setup.tyreAge
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      this.$refs.pressures.validate()
      if (!this.valid) return
      const data = {
        tyres: this.tyres,
        tyreSet: this.tyreSet,
        tyreAge: parseFloat(this.tyreAge),
        targetTyrePressure: {
          front: parseFloat(this.local.front),
          rear: parseFloat(this.local.rear)
        }
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
