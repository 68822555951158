<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Setup Information</div>
        <setup-info-form ref="form" :setup="local" noSubmit @validate="valid = $event" />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>

    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Info </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupInfoForm from '@components/setup/InfoForm.vue'

export default {
  name: 'TheSetupInfoUpdate',
  components: { SetupInfoForm },
  props: {
    info: {
      type: Object,
      default() {
        return {
          title: '',
          notes: ''
        }
      }
    },
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.info)
  },
  methods: {
    submit() {
      const data = this.$refs.form.submit()
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
