<template>
  <v-stepper vertical v-model="step" non-linear v-if="setup">
    <!--
      === Overall ===
    -->
    <v-stepper-step step="1" editable> Overall </v-stepper-step>
    <v-stepper-content step="1">
      <the-setup-info-update :info="setup" :uploading="uploading" :setupId="setup.id" @submit="updateInfo" />
    </v-stepper-content>

    <!--
      === Tool-Page ===
    -->
    <v-stepper-step step="2" editable> Setup Tools </v-stepper-step>
    <v-stepper-content step="2">
      <the-setup-tools ref="#setup-tools" :setupId="setup.id" @submit="addStep" />
    </v-stepper-content>

    <!--
      === Ride-Height ===
    -->
    <v-stepper-step step="3" editable> Ride-Height </v-stepper-step>
    <v-stepper-content step="3">
      <the-setup-ride-height-update
        ref="#ride-height"
        :config="setup.setup.rideHeightConfig"
        :uploading="uploading"
        :setupId="setup.id"
        :shimFactor="setup.car.shimFactors.rideHeight"
        @submit="updateRideHeight"
      />
    </v-stepper-content>

    <!--
      === Weight ===
    -->
    <v-stepper-step step="4" editable> Weight </v-stepper-step>
    <v-stepper-content step="4">
      <the-setup-weight-update
        ref="#weight"
        :config="setup.setup.weightConfig"
        :uploading="uploading"
        :setupId="setup.id"
        :shimFactor="setup.car.shimFactors.weight"
        @submit="updateWeight"
      />
    </v-stepper-content>

    <!--
      === Toe ===
    -->
    <v-stepper-step step="5" editable> Toe </v-stepper-step>
    <v-stepper-content step="5">
      <the-setup-toe-update
        ref="#toe"
        :config="setup.setup.toeConfig"
        :uploading="uploading"
        :setupId="setup.id"
        :frontShimFactor="setup.car.shimFactors.frontToe"
        :rearShimFactor="setup.car.shimFactors.rearToe"
        :wheelbase="setup.car.wheelbase"
        @submit="updateToe"
      />
    </v-stepper-content>

    <!--
      === Camber ===
    -->
    <v-stepper-step step="6" editable> Camber </v-stepper-step>
    <v-stepper-content step="6">
      <the-setup-camber-update
        ref="#camber"
        :config="setup.setup.camberConfig"
        :uploading="uploading"
        :setupId="setup.id"
        :frontShimFactor="setup.car.shimFactors.frontCamber"
        :rearShimFactor="setup.car.shimFactors.rearCamber"
        @submit="updateCamber"
      />
    </v-stepper-content>

    <!--
      === Springs ===
    -->
    <v-stepper-step step="7" editable> Springs </v-stepper-step>
    <v-stepper-content step="7">
      <the-setup-spring-update
        :config="setup.setup.springConfig"
        :uploading="uploading"
        :setupId="setup.id"
        @submit="updateSpring"
      />
    </v-stepper-content>

    <!--
      === Damper ===
    -->
    <v-stepper-step step="8" editable> Damper </v-stepper-step>
    <v-stepper-content step="8">
      <the-setup-damper-update
        :config="setup.setup.damperConfig"
        :uploading="uploading"
        :setupId="setup.id"
        @submit="updateDamper"
      />
    </v-stepper-content>

    <!--
      === Tyres ===
    -->
    <v-stepper-step step="9" editable> Tyres </v-stepper-step>
    <v-stepper-content step="9">
      <the-setup-tyre-update
        :setup="setup.setup"
        :config="setup.setup.targetTyrePressure"
        :uploading="uploading"
        :setupId="setup.id"
        @submit="updateTyres"
      />
    </v-stepper-content>

    <!--
      === Driverless ===
    -->
    <v-stepper-step step="10" editable> Driverless </v-stepper-step>
    <v-stepper-content step="10">
      <the-setup-driverless-update
        :setup="setup.setup"
        :uploading="uploading"
        :setupId="setup.id"
        @submit="updateDriverless"
      />
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import TheSetupInfoUpdate from '@components/setup/TheInfoUpdate.vue'
import TheSetupTools from '@components/setup/TheSetupTools.vue'
import TheSetupRideHeightUpdate from '@components/setup/TheRideHeightUpdate.vue'
import TheSetupWeightUpdate from '@components/setup/TheWeightUpdate.vue'
import TheSetupToeUpdate from '@components/setup/TheToeUpdate.vue'
import TheSetupCamberUpdate from '@components/setup/TheCamberUpdate.vue'
import TheSetupSpringUpdate from '@components/setup/TheSpringUpdate.vue'
import TheSetupDamperUpdate from '@components/setup/TheDamperUpdate.vue'
import TheSetupTyreUpdate from '@components/setup/TheTyresUpdate.vue'
import TheSetupDriverlessUpdate from '@components/setup/TheDriverlessUpdate.vue'

import { BaseSetup, UpdateBaseSetup } from '@graphql/Setups.gql'

function getUpsert(o) {
  return {
    update: Object.fromEntries(
      Object.entries(o)
        .filter(([k]) => k !== 'id')
        .filter(([, v]) => !!v)
        .map(([k, v]) => [k, { set: v }])
    ),
    create: o
  }
}

export default {
  name: 'SetupFlowPage',
  components: {
    TheSetupInfoUpdate,
    TheSetupTools,
    TheSetupRideHeightUpdate,
    TheSetupWeightUpdate,
    TheSetupToeUpdate,
    TheSetupCamberUpdate,
    TheSetupSpringUpdate,
    TheSetupDamperUpdate,
    TheSetupTyreUpdate,
    TheSetupDriverlessUpdate
  },
  apollo: {
    setup: {
      query: BaseSetup,
      variables: function () {
        return { where: { id: this.$route.params.id } }
      },
      update: (data) => {
        return data.baseSetup
      }
    }
  },
  data: () => ({
    hashs: [
      '#info',
      '#setup-tools',
      '#ride-height',
      '#weight',
      '#toe',
      '#camber',
      '#springs',
      '#damper',
      '#tyres',
      '#driverless'
    ],
    setup: undefined,
    uploading: false
  }),
  computed: {
    step: {
      get() {
        const steps = {
          '#info': 1,
          '#setup-tools': 2,
          '#ride-height': 3,
          '#weight': 4,
          '#toe': 5,
          '#camber': 6,
          '#springs': 7,
          '#damper': 8,
          '#tyres': 9,
          '#driverless': 10
        }
        const nextStep = steps[this.$route.hash] || 1
        if ([3, 4, 5, 6].some((s) => s === nextStep) && this.$refs[this.$route.hash]) {
          this.$refs[this.$route.hash].openShimCalculator()
        }
        return nextStep
      },
      set(step) {
        const hash = this.hashs[step - 1]
        this.$router.push({ name: 'SetupFlow', params: { id: this.$route.params.id }, hash })
      }
    }
  },
  methods: {
    addStep() {
      this.step++
    },
    applyUpdateBaseSetupMutation(variables) {
      this.$apollo
        .mutate({ mutation: UpdateBaseSetup, variables })
        .then(() => {
          this.uploading = false
          this.step++
        })
        .catch((res) => {
          this.uploading = false
          this.$store.commit('openErrorDialog', res)
        })
    },
    updateInfo(data) {
      this.uploading = true
      const variables = {
        where: { id: this.setup.id },
        data: {
          notes: { set: data.notes },
          title: { set: data.title }
        }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateRideHeight(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { rideHeightConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateWeight(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { weightConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateToe(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { toeConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateCamber(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { camberConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateSpring(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { springConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateDamper(data) {
      this.uploading = true
      const variables = {
        data: { setup: { update: { damperConfig: { upsert: getUpsert(data) } } } },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateTyres(data) {
      this.uploading = true
      const variables = {
        data: {
          setup: {
            update: {
              tyres: { set: data.tyres },
              tyreAge: { set: data.tyreAge },
              tyreSet: { set: data.tyreSet },
              targetTyrePressure: { upsert: getUpsert(data.targetTyrePressure) }
            }
          }
        },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    },
    updateDriverless(data) {
      this.uploading = true
      const variables = {
        data: {
          setup: {
            update: {
              dvMounted: { set: data.dvMounted }
            }
          }
        },
        where: { id: this.setup.id }
      }
      this.applyUpdateBaseSetupMutation(variables)
    }
  }
}
</script>
