<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Toe</div>
        <setup-wheel-form ref="form" v-model="local" unit="° (deg)" @validate="valid = $event" />
        <setup-toe-helper
          ref="helper"
          :frontShimFactor="frontShimFactor"
          :rearShimFactor="rearShimFactor"
          :wheelbase="wheelbase"
          @copy="setConfig"
        />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>
    <v-alert v-model="copyAlert" color="green" type="success" dense>Copied front and rear targets!</v-alert>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Toe </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupToeHelper from '@/components/setup/ConfigToeHelper.vue'
import SetupWheelForm from '@components/setup/WheelForm.vue'

const defaultConfig = {
  frontLeft: '',
  frontRight: '',
  rearLeft: '',
  rearRight: ''
}

export default {
  name: 'TheSetupToeUpdate',
  components: { SetupWheelForm, SetupToeHelper },
  props: {
    config: Object,
    uploading: Boolean,
    setupId: String,
    frontShimFactor: Number,
    rearShimFactor: Number,
    wheelbase: Number
  },
  data: () => ({
    valid: false,
    copyAlert: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.config || defaultConfig)
  },
  methods: {
    setConfig(conf) {
      this.local = { ...conf }
      this.copyAlert = true
      setTimeout(
        function () {
          this.copyAlert = false
        }.bind(this),
        3000
      )
    },
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        frontLeft: parseFloat(this.local.frontLeft),
        frontRight: parseFloat(this.local.frontRight),
        rearLeft: parseFloat(this.local.rearLeft),
        rearRight: parseFloat(this.local.rearRight)
      }
      this.$emit('submit', data)
      return data
    },
    openShimCalculator() {
      this.$refs.helper.open()
    }
  }
}
</script>
