<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Springs</div>
        <setup-axis-double-form ref="form" v-model="local" unit="N/mm" @validate="valid = $event" />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Spring </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupAxisDoubleForm from '@/components/setup/ConfigSpringForm.vue'

const defaultConfig = {
  frontHeave: '',
  frontRoll: '',
  rearHeave: '',
  rearRoll: ''
}

export default {
  name: 'TheSetupSpringUpdate',
  components: { SetupAxisDoubleForm },
  props: {
    config: Object,
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    copyAlert: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.config || defaultConfig)
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        frontHeave: parseFloat(this.local.frontHeave),
        frontRoll: parseFloat(this.local.frontRoll),
        rearHeave: parseFloat(this.local.rearHeave),
        rearRoll: parseFloat(this.local.rearRoll)
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
