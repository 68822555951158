<template>
  <v-container class="pa-0">
    <v-container class="pa-0 ma-6">
      <v-sheet>
        <div class="text-overline pa-5">Setup Tools</div>
        <setup-tools ref="form" :setup="local" noSubmit @validate="valid = $event" />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>

    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn color="primary" @click="submit"> All tools available </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupTools from '@components/setup/SetupTools.vue'

export default {
  name: 'TheSetupTools',
  components: { SetupTools },
  props: {
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.info)
  },
  methods: {
    submit() {
      const data = {}
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
