<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Driverless</div>

        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch v-model="dvMounted" label="Driverless Mounted?"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Driverless </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TheSetupDriverlessUpdate',
  props: {
    setup: Object,
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    copyAlert: false,
    dvMounted: false
  }),
  created() {
    this.dvMounted = this.setup.dvMounted
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        dvMounted: this.dvMounted
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
