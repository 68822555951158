<template>
  <v-container class="pa-0">
    <v-container class="pa-0 mb-6">
      <v-sheet>
        <div class="text-overline pa-5">Damper</div>
        <setup-config-damper-form ref="form" v-model="local" unit="clicks" @validate="valid = $event" />
      </v-sheet>
      <v-progress-linear :indeterminate="uploading" query reverse></v-progress-linear>
    </v-container>
    <v-row class="pa-3">
      <v-spacer></v-spacer>
      <v-btn class="float-right mr-2" plain text :to="`/setup/${setupId}`"> Back to overview </v-btn>
      <v-btn :disabled="!valid" color="primary" @click="submit"> Save Damper </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import SetupConfigDamperForm from '@/components/setup/ConfigDamperForm.vue'

const defaultConfig = {
  frontHeaveRebound: '',
  frontHeaveBump: '',
  frontRollRebound: '',
  frontRollBump: '',
  rearHeaveRebound: '',
  rearHeaveBump: '',
  rearRollRebound: '',
  rearRollBump: ''
}

export default {
  name: 'TheSetupDamperUpdate',
  components: { SetupConfigDamperForm },
  props: {
    config: Object,
    uploading: Boolean,
    setupId: String
  },
  data: () => ({
    valid: false,
    copyAlert: false,
    local: {}
  }),
  created() {
    this.local = _cloneDeep(this.config || defaultConfig)
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        frontHeaveRebound: parseFloat(this.local.frontHeaveRebound),
        frontHeaveBump: parseFloat(this.local.frontHeaveBump),
        frontRollRebound: parseFloat(this.local.frontRollRebound),
        frontRollBump: parseFloat(this.local.frontRollBump),
        rearHeaveRebound: parseFloat(this.local.rearHeaveRebound),
        rearHeaveBump: parseFloat(this.local.rearHeaveBump),
        rearRollRebound: parseFloat(this.local.rearRollRebound),
        rearRollBump: parseFloat(this.local.rearRollBump)
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
